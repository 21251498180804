.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family:"Satoshi medium";
  /* src: url(../../faculty/src//assets/fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf); */
  src: url("../src/assets/fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf");
}

@font-face {
  font-family: "Satoshi-bold";
  src: url("../src/assets/fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Bold.otf");
  /* src:url(../../faculty/src//assets/fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Bold.otf) */
}
@font-face {
  font-family: "syhet";
  src: url("../src/assets/fonts/ABeeZee/ABeeZee-Italic.ttf");
}

/* ttf font  */

@font-face {
  font-family: "Satoshi-ttf";
  src: url("../src/assets/fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium-ttf.ttf");
}



.btn{
  border:none;
  border-radius: 3px;
  font-size: 16px;
  padding: 4px;
}

.headerAppraisal{
  font-size: 21px;
  font-family: Satoshi-bold ;
}

.background-color-headers{
  background-color: #F7F8FA;
  padding: 4px;
}

.modalposition{
  position: absolute;
  /* left: 50%; */
  top: 30%;
  transform: translate(-50%, -50%);
}
.CreateBtn,
.CreateBtn:active {
  background-color: #4075D3 !important;
  color: #ffffff !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  height: 36px !important;
  width: 96px !important;
  font-size: 12px !important;
}
.alertApp{
text-align: left;
font: "13px Satoshi medium";
letter-spacing: 0px;
color: #CC5151;
opacity: 1;
margin-top: -3rem!important;
}
.sendApproval,
.sendApproval:active {
  background-color: #4075D3 !important;
  color: #ffffff !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  height: 36px !important;
  width: 8rem !important;
  font-size: 12px !important;
}
.greyforview{
  font:12px satoshi medium !important;
letter-spacing: 0px !important;
color: #7784A1 !important;
opacity: 1 !important;
bottom: 0px !important;
}
.is-invalid{
  font:12px satoshi medium !important;
  letter-spacing: 0px !important;
  color: #dc3545;
  opacity: 1 !important;
}
.imageWidth{
  top: 194px;
  left: 268px;
  width: 96px;
  height: 124px;
  opacity: 1;
  border-radius: 5px;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
}
.points{
  text-align: right;
font: 16px Satoshi-bold !important;
letter-spacing: 0px;
color: #4075D3;
opacity: 1;
}
.filter{
  margin-left: 45% !important;
}


.search {
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}

.search input {
  height: 35px;
  text-indent: 25px;
  border: 2px solid #d6d4d4;
}

.search .fasearch {
  position: absolute;
  top: 10px;
  left: 10px;
}

.search .close {
  position: absolute;
  top: 12px;
  right: 10px;
}

.closebtn {
  width: 5px;
  height: 5px;
}
.uploadButton{
  border: 1px solid #D0D0E0;
  border-radius: 7px; 
  opacity: 1;
}

.inputFields{
  border:1px solid #A0AECB !important
}
.select{
  color: "grey" !important;
  font-size: "14px" !important;
}
.options{
  color: "black" !important;
  font-size: "14px" !important;
}