.logoutImg {
    width: 2.625rem;
    height: 2.625rem;
  }
  .packagewidthheader {
    margin-left: 86px;
    background-color: white;
  }
  @media screen and (max-width: 767px) {
    .packagewidthheader {
      margin-left: 0px;
    }
  }
  