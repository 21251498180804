
.containerss{
  /* width: 258px; */
  height:150px;
  border: 1px solid  #D0D0E0;
}

.firstRow{
    padding: 10px 18px 0 18px;
}

.editIcon{
    width: 13px;
    height: 13px;
}

.secondRow{
    /* padding: 10px 18px 0 18px; */
    padding: 7px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    border-top: 1px solid #D0D0E0;
}

.courses{
    font:13px satoshi medium;
    text-decoration: underline;
    color: #4075D3;
}

.userimg{
    width:30px;
    padding-top:2px;
    border-radius: 15px;
}

.designation{
    color:#D0D0E0;
    font: 13px satoshi medium ;
}

.degree{
    font: 13px Satoshi-bold ;
}

.userName{
    font: 13px satoshi medium ;
}


