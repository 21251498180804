.input-box1 {
    height: calc(100vh - 100px);
  }
  /* .updatePasswordTitle{
    font-family:"Satoshi medium";
  } */

  label {
    color: #1b1922;
    opacity: 1;
    font-size: 14px;
  }
  .inner-addon {
    position: relative;
  }
  .inner-addon .far,
  .inner-addon .fas {
    position: absolute;
    padding: 3px 30px;
    z-index: 100;
    cursor: pointer;
  }
  .left-addon .far,
  .left-addon .fas {
    left: 0px;
  }
  .right-addon .far,
  .right-addon .fas {
    right: -20px;
  }
  
  .left-addon input {
    padding-left: 30px;
  }
  .right-addon input {
    padding-right: 30px;
  }
  .pwd-control {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  .error-message {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .error-message.show {
    opacity: 1;
  }
  