.wrapper {
  font-family: Satoshi Medium !important;
  background-color: #261d56;
}

.padTop {
  padding-top: 0.813rem;
  font: 12px Satoshi-Medium;
}

.padTop2 {
  padding-top: 3rem;
}

.packagewidth {
  width: 86px;
}

.sideBarLink {
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 0.5rem;
  font-family:'Satoshi-Medium';
  font-size: 12px;

  a:hover {
    color: #ffffff;
  }
}

.homeMargin {
  margin-top: 2.625rem;
}

.mobView {
  background: #261d56 0% 0% no-repeat padding-box;
  box-shadow: 1px 0px 0px #d0d0e0;
  height: 65px;
}
.offcanvas.offcanvas-end {
  top:-15px !important;
}
.hamburg {
  background: #ffffff;
  border: 1px solid #e3e6e8;
  border-radius: 5px;
}

.cursorPointer {
  cursor: pointer !important;
}

.imgWidth {
  width: 14.125rem;
  height: 3.125rem;
}

.imgMenu {
  background-color: #ffffff;
}

.offcanvas-backdrop.show {
  opacity: 0 !important;
}

.offcanvas {
  margin-top: 80px;
}

.offBg {
  background: #261d56 0% 0% no-repeat padding-box !important;
  box-shadow: 1px 0px 0px #d0d0e0;
}

.sideBarLink{
  font:12px Satoshi medium;
}

@media (max-width: 1024px) and (min-width: 768px) {
  .sideBarLink {
    font-size: 10px;
  }
}

.heightSidebar {
  min-height: calc(100vh - 80px) !important;
}

.bottomstyle {
  bottom: 20px;
}

.disabled{
  cursor: not-allowed;
pointer-events: none;
}