.CreateBtn,
.CreateBtn:active {
  background-color: #4075d3;
  color: #ffffff;
  border-radius: 5px;
  opacity: 1;
  height: 36px;
  width: 96px;
  font-size: 12px;
}
.labelFont {
  font-size: 13px;
  color: #7784A1;
}
.uploadBtn {
  border: 1px solid #ffffff; 
  transition: border-color 0.3s; 
  height: 40px;
  width: 86px;
}

.uploadBtn:active {
  border-color: #cccccc; 
}

.link {
  color: inherit;
  text-decoration: none; 
}
.divider {
  border-left: 1px solid #000; 
  height: 40px; 
  margin-right: 20px; 
}
/* @media (max-width: 768px) {
  .uploadBtn {
    border: 1px solid #ffffff; 
    transition: border-color 0.3s; 
    height: 30px;
    width: 76px;
    font-size: 12px;
  }
} */


/* @media (min-width: 320px) and (max-width: 344px) {
  .uploadBtn {
    border: 1px solid #ffffff; 
    transition: border-color 0.3s; 
    height: 30px;
    width: 96px;
    font-size: 12px;
    border-radius: 10px;
  }
} */
.boxText {
  height: 38px;
  border: 1px solid #000;
}

.boxtextellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.browsetext {
  font-size: 13px;
}
.lname {
  letter-spacing: 0px;
  color: #1b1922;
  opacity: 1;
  font-size: 13px;
  background-color: #ffff;
}

@media (min-width: 576px){ 

 }


 .mobilescreen {
  margin-top: 0px; 
}


 @media (max-width: 768px) {
  .mobilescreen {
    margin-top: 120px; 
  }
}
.margintopalign {
  margin-top: 0; 
}

@media (min-width: 768px) and (max-width: 863px) {
  .margintopalign {
      margin-top: 18px !important; 
  }
}
