body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainheader{
  font: 21px Satoshi-bold !important;
}

.mainheader2{
  font:12px Satoshi medium !important;
  color: #7784A1;
}

.addnewbtn{
  background-color: #4075D3 !important;
  color: #ffffff !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  height: 36px !important;
  width: 96px !important;
  font-size: 12px !important;
}


.saveBtn{
  background-color: white !important;
  color: black !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  height: 36px !important;
  width: 96px !important;
  font-size: 12px !important;
  border: 1px solid #A0AECB;
}

.addresult{
  display: flex;
  justify-content: space-between;
  font: 13px Satoshi-bold;
}

.addresults{
  /* border-bottom: 3px solid #4075D3 ; */
  text-decoration: underline 3px solid #4075D3 ; 
  margin-top: 10px;
}

.underline{
  border-bottom: 2px solid blue;
}
.addresulttab{
  /* border-bottom: 3px solid #4075D3 ; */
  text-decoration: underline 3px solid #4075D3 ; 
  
}
.tabfont{
  font: 13px Satoshi-bold;
}

/* .tablink span{
  border-bottom: 3px solid #4075D3 ;
} */

.employeeView{
    font: 13px satoshi medium;
}
.searchicon{
height:16px;
width:16px;
}

.greytext{
  margin-top: 8px;
  margin-bottom: 16px;
}

.greytext span {
color:#7784A1;
font:12px satoshi medium;
}
.greyfont{
  color:#7784A1;
  font:12px satoshi medium;
}

.subheader{
  font: 16px Satoshi-bold !important;
}
.search {
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}
 
.search input {
  height: 36px;
  text-indent: 25px;
  border: 2px solid #d6d4d4;
}
 
.search .fasearch {
  position: absolute;
  top: 12px;
}
 
.search .close {
  position: absolute;
  top: 12px;
  right: 11px;
}
 
.closebtn {
  width: 5px;
  height: 5px;
}
/* input[type="date"] {
  font: 13px Satoshi medium;
  color: #7784A1;
} */
input[type="file"] {
  font: 13px Satoshi medium;
  color: #7784A1;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.loginHeader{
  font: 21px Satoshi-bold !important;
letter-spacing: 0px !important;
color: #1B1922 !important;
opacity: 1 !important;
}
.loginContent{
  font: 13px Satoshi medium !important;
letter-spacing: 0px !important;
color: #1B1922 !important;
opacity: 1 !important;
}

@media only screen and (max-width: 920px) {
  .signIn{
    opacity: 1 !important;
    height: 36px !important;
    width: 17rem !important;
    border: 1px solid #747775 !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    font: 14px Satoshi-bold !important;
    }
}
@media only screen and (min-width: 920px) {
  .signIn{
    opacity: 1 !important;
    height: 36px !important;
    width: 22rem !important;
    border: 1px solid #747775 !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    font: 14px Satoshi-bold !important;
    }
}

.transform {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100vh !important; 
}



@media only screen and (max-width: 990px) {
 
.AddNewBtnDiv{
    margin-top: 10px !important
}

}

.imgW{
  top: 188px !important;
left: 270px !important; 
width: 96px !important; 
height: 96px !important;
}
.me-4-5 {
  margin-right: 1.85rem; 
}
.btn-close{
  font-size: 16px;
  margin-top: 6px;
  margin-right: 5px !important;
  opacity: 1 !important;
  --bs-btn-close-bg: url('../src/assets/close.svg') !important;
}
.select{
  color: "grey" !important;
  font-size: "14px" !important;
}

.options{
  color: black !important;
  font-size: 14px !important;
}