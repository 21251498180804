.CreateBtn,
.CreateBtn:active {
  background-color: #4075d3;
  color: #ffffff;
  border-radius: 5px;
  opacity: 1;
  height: 36px;
  width: 96px;
  font-size: 12px;
}
.labelFont {
  font-size: 13px;
}
.link {
  color: inherit;
  text-decoration: none;
}

.input-container {
  position: relative;
}



.percentage-symbol {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 16px;
  color: #000;
}

.currency-symbol {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 16px;
  color: #000; 
}
.currency .form-control {
  padding-left: 25px;
}