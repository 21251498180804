.valuesDiv{
   border: 1px solid #D0D0E0;
   border-radius:5px;
}

.valuesDiv {
    font: 13px satoshi-bold; 
    /* padding: 21px 0 20px 15px; */
    border: 1px solid #D0D0E0;
    border-radius: 5px;
    opacity: 1;
    margin: 16px 0 24px 0 !important;
}

.paddingDiv{
    padding: 21px 0 20px 15px;
}

.mainheaderViewProficiency{
    font: 16px satoshi-bold ;
  
    /* margin-top: 40px; */
}

.boxContent{
    font: 13px satoshi-bold ;
}

.dateSelector{
    font: 13px satoshi medium;
}

.maintextProf{
    font: 13px satoshi-bold;
}

.subTextProf{
    font: 13px satoshi medium;
}

.noOfVerified{
        font: 12px satoshi medium;
        color: #7784A1;
}

.acaYearPicker{
    margin-bottom: 40px;
}

/* .textdropdown{
    font: 13px satoshi medium;
    margin-top: 200px ;
} */

.imgW{
    top: 188px;
left: 270px;
width: 96px;
height: 96px;
  }
  .statusboxwidth{
    white-space: nowrap;
  }
  @media only screen and (min-width:500px){
  .statusboxwidth{
    width:120px;
  }
}