.CreateBtn,
.CreateBtn:active {
  background-color: #4075d3;
  color: #ffffff;
  border-radius: 5px;
  opacity: 1;
  height: 36px;
  width: 96px;
  font-size: 12px;
}
.labelFont {
    font-size: 13px;
  }
  .link {
    color: inherit;
    text-decoration: none; 
  }

  .durationBox{
    border-radius: 6px;
    border: 1.5px solid #E8EBF0;
    padding: 6px;
  }
  input[type=date][value=""]:not(:focus) { 
    color: transparent !important; 
    content: attr(placeholder) !important;
  }
  .dateplaceholder {
    color:grey;
    font-size:12px;
    font-family: "Satoshi medium";
  }
  input[type="date"][value=""]:not(:focus) ~ .dateplaceholder {
    display: block;
  }
  input[type="date"]:focus ~ .dateplaceholder {
    display: none;
  }
  input[type="date"]:valid ~ .dateplaceholder {
    display: none;
  }
  input[type="date"]:invalid ~ .dateplaceholder {
    display: none;
  }
  