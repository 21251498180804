.sideWidth {
  width: 8.9rem !important;
}

.complete {
  font: 0.813rem Satoshi-bold !important;
}

.sideNav {
  a {
    text-decoration: none;
    color: #1b1922 !important;
  }

  ul {
    list-style: none;
    padding: 0rem;
  }

  .tab {
    cursor: pointer !important;
    color: #1b1922 !important;
    font: 0.75rem Satoshi Medium !important;
    text-align: start;
    padding-left: 1rem !important;
    padding-top: 6px;
    padding-bottom: 7px;
    width: 100%;
  }

  :hover {
    color: #1b1922 !important;
  }

  .active {
    background-color: #D3DFF8 !important;
    border-radius: 0rem;
    cursor: pointer !important;
    color: #1b1922 !important;
  }
}

.headingSSB{
   font: 16px Satoshi-bold;
}

.paddingContent {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  overflow: hidden;
}

.bgSecondSidebar {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.me2 {
  margin-right: 2.25rem;
}

.borderend {
  border-right: 0.063rem solid #dee2e6;
  min-height: calc(100vh - 82px);
}

.marginleft {
  margin-left: 5.375rem !important;
}

.titleSSB{
  font: 12px Satoshi medium;
}

@media (max-width: 767px) {
  .paddingContent {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .borderend {
    border-right: none;
    border-bottom: 0.063rem solid #dee2e6;
    min-height: unset;
  }

  .sideWidth {
    width: 100% !important;
  }

  .marginleft {
    margin-left: 0rem !important;
  }

  .me2 {
    margin-right: 1rem;
  }
}
@media (min-width: 767px) {
  .fix {
    position: fixed !important;
  }

  .fixleft {
    margin-left: 10rem;
  }
}
