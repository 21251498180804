  @media (min-width: 320px) and (max-width: 720px) {
    .offcanvas {
      width: 90%;
    }
  }
  .ApplyBtn {
    border: 1px solid #d0d0e0;
    border-radius: 7px;
  }
  .clearFilter {
    text-decoration: underline !important;
    font-size: 12px;
  }

  /* .tablink {
    padding-bottom: 5px; 
    margin-right: 10px; 
  }
  
  .active {
    border-bottom: 2px solid rgb(181, 181, 235); 
  } */
  .btnwith{
    width: 136px;
  }
 
.input-box {
  position: relative;
  width: 100%;
  max-width: 60px;
  height: 55px;
  margin: 0 50px;
  background-color: #fff;
  border-radius: 6px;
  transition: all 0.5s ease-in-out;
}
.input-box.open {
  max-width: 350px;
}
.input-box input {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #333;
  padding: 0 15px;
  border: none;
  border-radius: 6px;
  outline: none;
  transition: all 0.5s ease-in-out;
}
.input-box.open input {
  padding: 0 15px 0 65px;
}

.input-box .searchs {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer;
}
.input-box.open .searchs {
  border-radius: 6px 0 0 6px;
}
.searchs .search-icon {
  font-size: 30px;
  color: #1c1c1c;
}
.input-box .close-icon {
  position: absolute;
  top: 50%;
  right: -45px;
  font-size: 30px;
  color: #1c1c1c;
  padding: 5px;
  transform: translateY(-50%);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
}
.input-box.open .close-icon {
  transform: translateY(-50%) rotate(180deg);
  pointer-events: auto;
  opacity: 1;
}
.cancelIcons{
  margin-top: 15px;
}
.search-input::placeholder {
  padding-left: 20px; 
}
::-webkit-calendar-picker-indicator{ 
  background-image: url('../../../../assets/calendar.png');
      background-position:center;
       background-repeat:no-repeat;
}
input[type=date][value=""]:not(:focus) { 
  color: transparent; 
  content: attr(placeholder) !important;
}
input[type=month][value=""]:not(:focus) { 
  color: transparent; 
  content: attr(placeholder) !important;
}
.dateplaceholder {
  color:grey;
  font-size:12px;
  font-family: "Satoshi medium";
}
input[type="date"][value=""]:not(:focus) ~ .dateplaceholder {
  display: block;
}
input[type="date"]:focus ~ .dateplaceholder {
  display: none;
}
input[type="date"]:valid ~ .dateplaceholder {
  display: none;
}
input[type="date"]:invalid ~ .dateplaceholder {
  display: none;
}
input[type=month][value=""]:not(:focus) ~ .dateplaceholder { 
  display: block;
}
input[type="month"]:focus ~ .dateplaceholder {
  display: none;
}
input[type="month"]:valid ~ .dateplaceholder {
  display: none;
}
input[type="month"]:invalid ~ .dateplaceholder {
  display: none;
}



.activefilter {
  /* border: 2px solid blue;   */

  border-radius: 80%;
  margin-left: 2px;
  padding: 2px 4px;
  font-size: 10px;
  background-color: #4075D3;
  color: white;
}
.btn.ApplyBtn:hover {
  background-color: #f0f0f0; 
  color: #000; 
  border-color: #b0b0b0; 
  transition: all 0.3s ease; 
  }
  
  .btn.ApplyBtn:active {
  background-color: #e0e0e0; 
  color: #333; 
  border-color: #808080; 
  transform: scale(0.98); 
  transition: all 0.2s ease;
  }