.CreateBtn,
.CreateBtn:active {
  background-color: #4075d3;
  color: #ffffff;
  border-radius: 5px;
  opacity: 1;
  height: 36px;
  width: 96px;
  font-size: 12px;
}
.labelFont {
    font-size: 13px;
  }
  .link {
    color: inherit;
    text-decoration: none; 
  }
  .quantity::-webkit-inner-spin-button, 
.quantity::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}