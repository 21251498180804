.partiTable {
    overflow-x: auto;
 
    thead {
        font: 0.813rem Satoshi-bold !important;
    }

    tbody {
        font: 0.813rem Satoshi Medium !important;
    }

    tr:nth-child(odd) {
        background-color: #F7F8FA;
    }
    tr:nth-child(even) {
      background-color: white;
  }
    td,
    th {
        text-align: left;
        padding: 1rem;
        white-space: nowrap;
    }

    /* border: 0.063rem solid #d0d0e0 !important; */
    border: none !important;
    border-radius: 0.438rem !important;
}

.extraDatas{
 font-size: 16px;
 font-family:Satoshi-bold ;
}
/* 
.extraDatas{
   
} */


.bgnone{
    background: none !important;
}

.txtunderline{
    text-decoration: underline;
}

.profiletable{
color: #4075D3;
 font-size: 12px;
 font-family:Satoshi Medium ;
 text-decoration: underline;
}

.btntable{
    height: 36px;
    background: #4075d3 0% 0% no-repeat padding-box;
    border-radius: 7px;
    color: #ffffff;
    font-size: 12px;
    border: none;
}

.tabfontemp{
    font: 13px Satoshi-bold;
    border-bottom: 3px solid #4075D3;
}

.avataremp{
    width: 15px;
    height: 12px;
    /* height: 12px; */
}

.custom-modal .modal-dialog {
    max-width: 80%; /* You can adjust the width percentage as needed */
  }

  .ActivityTab{
    background-color: #F7F8FA;
  }

.paddingView{
    margin-top: 55px !important;
}

.activities{
    font: 21px satoshi-bold;
    padding-bottom: 20px;
}

.ActivityTab{
    padding: 10px 0 ;
}

.createdOn{
    font: 13px satoshi-bold;
    /* padding-top: 10px; */
}

.createdDate{
    font: 13px satoshi medium;
    color: grey;
    padding-top: 6px;
    /* padding-bottom: 10px */
}

.comment{
    font: 13px satoshi medium;
    
}

.activitiesTab{
    display: flex;
    flex-direction:column;
    gap: 15px;
    padding-top: 10px;
}

.activityIcons{
    margin-left: 9px;
}

.statusView{
    /* padding-bottom: 4px; */
    padding-right: 6px;
    padding-top: 2px;
    font: 12px satoshi-bold;
    font-weight: bold;
}

.bg-YetToSubmitView{
    background-color: #F7F8FA;
    padding:1 !important;
    border-radius:20px;
    font: 10px;
    font-weight: bold;
  }
  
  .bg-VerifiedView{
    background-color: #D3F8F1;
    padding:1 !important;
    border-radius:20px;
    font: 10px;
    font-weight: bold;
  }
  
  .bg-PendingView{
    background-color: #F8EBB8;
    padding:1 !important;
    border-radius:20px;
    font: 10px;
    font-weight: bold;
  }
  
  .bg-RejectedView{
    background-color: #FDDFDF;
    padding:1 !important;
    border-radius:20px;
    font: 9px;
    font-weight: bold;
  }

  .statusIconView{
    width: 11px; 
    margin: 4px 4px 4px 4px
  }

  .ProficiencyValidation span:first-child{
  font: 21px satoshi-bold;
  }


    .requestt{
    font: 12px satoshi medium !important;
    color: #7784A1;
    margin-top: 8px !important;

   }

   .empDetails{
    margin-top: 24px;
   }

   .empDetails span:first-child{
    font: 16px satoshi-bold;
   }

   .empDetails span:nth-child(2){
    font: 13px satoshi medium;
    padding: 6px 0;
   }

   .ProficiencyValidationTab{
    margin-bottom: 44px;
    border-bottom: 2px solid #D0D0E0;
   }

   .btnValidation{
    /* background-color: #4075D3 !important; */
    color: black !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    height: 36px !important;
    width: 130px !important;
    font: 12px satoshi medium !important;
    margin: 0 3px ;
    border: none;
  }

  .textBox{
    border: 1px solid #A0AECB;
    width: 262px;
    height: 83px;
    font:13px satoshi medium;
    padding:2px
  }


  .textBox::placeholder{
    font: 13px satoshi medium;
    padding: 13px 0 0 14px; 
  }
.textboxplaceholder::placeholder{
  padding: 3px;
}
  .rejectModal{
    /* margin: 0 93px !important; */
  }

  .dataDec{
    font: 13px Satoshi-bold !important;
letter-spacing: 0px;
color: #1B1922;
opacity: 1;
  }


  .bacdept{
    font: 13px satoshi medium !important;
  }
  textarea {
    resize: none;
  }