.inputforms{
    border-radius: 5px;
    border: 1px solid #A0AECB;  
    height:36px;
}

.inputforms:focus{
  outline: none;
}

.labelinput{
  font: 13px Satoshi medium;
 padding-bottom: 11px;
}

.form-control::placeholder{
font: 12px Satoshi medium;
}

.backtext{
  font: 13px Satoshi medium;
  text-decoration: none;
  color: inherit;
}
input[type=date][value=""]:not(:focus) { 
  color: transparent !important; 
  content: attr(placeholder) !important;
}
.dateplaceholder {
  color:grey;
  font-size:12px;
  font-family: "Satoshi medium";
}
input[type="date"][value=""]:not(:focus) ~ .dateplaceholder {
  display: block;
}
input[type="date"]:focus ~ .dateplaceholder {
  display: none;
}
input[type="date"]:valid ~ .dateplaceholder {
  display: none;
}
input[type="date"]:invalid ~ .dateplaceholder {
  display: none;
}
