.kingsIcon{
    width: 5rem;
    height: 7rem;
}
.wordIcon{
    width: 10rem;
    height: 4.5rem;
    margin-top: 10px;
}
.font1{
    font-size: 10px;
    /* letter-spacing: 0px; */
    color: #1B1922;
    opacity: 1;
}