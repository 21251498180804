.partiTables {
    overflow-x: auto;
  
    thead {
        font: 13px Satoshi-bold !important;
    }
  
    tbody {
        font: 13px Satoshi Medium !important;
    }
  
    tr:nth-child(even) {
        background-color: #F7F8FA;
    }
  
    td,
    th {
        text-align: left;
        padding: 1rem;
        white-space: nowrap;
    }
    border: 1px solid #d0d0e0 !important;
    border-radius: 0.438rem !important;
  }
  .modal-footer .modal-title {
    text-decoration: none;
  }

.table-head{
    font: 13px Satoshi-bold !important;
}

.table-datas{
    font: 13px Satoshi Medium !important;
}

.table-SNo{
    font: 13px Satoshi Medium !important;
}

.editicons{
   font-size: 17px;
   margin-right: 8px;
   margin-left: 10px;
}

.DeleteIcon{
    font-size: 17px;
 }

.tableCell {
    border-bottom: none; 
  }

  .last-table-cell {
    border: none;
  }

  .modal-header{
    border-bottom: none !important;
    text-align: center;
    /* margin-left: 140px; */
  }

  .modal-footer{
    border-top: none !important;
  }
/* 
  .modal-content{
   align-items: center;
  } */
  .centered{
    align-items: center;
  }
  /* .btn-close{
    margin-right: 5px !important;
  } */
 .cancelBtn {
  border: 1px solid #d0d0e0 !important;
  font-size: 12px !important;
  width: 6rem !important;
  height: 37px !important;
  font: 13px Satoshi Medium !important;
}
  .btnBack{
    background-color: #4075d3 !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    height: 37px !important;
    width: 96px !important;
    font: 12px Satoshi Medium !important;
    white-space: nowrap;
  }
  .fontModel{
    text-align: center;
    letter-spacing: 0px;
    color: #1B1922;
    opacity: 1;
    font: 13px Satoshi Medium !important;
  }
  .modal-title{
    /* text-align: left; */
    font: 16px Satoshi-bold  !important;
  }
  
.SubmittedBtn {
  background:#F7F8FA 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  width: 6rem;
  height: 2.25rem;
  font: 0.813rem Satoshi Medium !important;
  color: #7784A1!important;
  border:1px solid #7784A1
}

.bg-YetToSubmit{
  background-color: #F7F8FA;
  padding: 1px;
  padding-top: 2px;
  border-radius: 10px;
  font: 11px;
  font-weight: bold;
  padding-right: 6px;
  padding-left: 1px;
}

.bg-Verified{
  background-color: #D3F8F1;
  padding: 1px;
  border-radius: 10px;
  font: 12px;
  font-weight: bold;
  padding-right: 6px;
  padding-left: 1px;
}

.bg-Pending{
  background-color: #F8EBB8;
  padding: 1px;
  border-radius: 10px;
  font: 12px;
  font-weight: bold;
  padding-right: 6px;
  padding-left: 1px;
}

.bg-Rejected{
  background-color: #FDDFDF;
  padding: 1px;
  padding-right: 6px;
  padding-left: 1px;
  border-radius: 10px;
  font: 12px;
  font-weight: bold;
}

.statusIcon{
  width: 11px; 
  margin: 2px 4px 4px 4px
}
.statusIconY{
  width: 13.5px; 
  margin: 0px 4px 4px 4px
}
.underline-text {
  text-decoration: underline;
}
.test{
    width: 190px;
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block !important;
}
/* .MuiTableRow-root{
  vertical-align: super !important;
} */

