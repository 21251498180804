.RequestStatus{
    font: 13px satoshi-bold ;
    color:#7784A1;
    border: none;
    background-color:white
}

/* .RequestStatus:focus{
   color: black;
   border-bottom: 3px solid #4075D3;
} */

.RequestStatus.clicked {
    color: black;
    border-bottom: 3px solid #4075D3;
  }