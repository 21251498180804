
  
.nav-tabs {
    border-bottom: none;
  }
  .uborder {
    font: 13px satoshi-bold !important;
    width: max-content;
    /* width: 140px; */
  }
  .under{
    border-bottom: 0.125rem solid #D0D0E0 !important;
    color: #7784a1;
    width: 100vw;
    margin-bottom: 1.7rem;
  }
  @media only screen and (max-width:767px){
    .under{
      width: 100% !important;
    } 
  }
    .uborder.active {
      color: #1b1922;
      border-bottom: 3.5px solid #4075d3 !important;
      /* width: 140px; */
      width: max-content;
      /* height: 50px; */
    }
  .greenColor {
    background-color: #D3F8F1;
    border-radius: 5px;
  }
  .num {
    font-size: 10px;
    background: #d0d0e0;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-link.active.num {
    background: #4075D3 0% 0% no-repeat padding-box !important;
    color: #fff !important;
    
  }
  .CreateBtn,
  .CreateBtn:active {
    background-color: #4075D3;
    color: #ffffff;
    border-radius: 5px;
    opacity: 1;
    height: 36px;
    width: 96px;
    font-size: 12px;
  }
  .addnewbtn{
    border:none;
    border-radius: 3px;
    font-size: 12px;
    padding: 4px;
    background-color:#4075D3;
    height:36px;
    width:92px;
  }
  .addprevbtn{
    border:none;
    border-radius: 3px;
    font-size: 12px;
    padding: 4px;
    background-color: #FFFFFF;
    height:36px;
    width:92px;
  }
/*   
  .prevBtn:active {
    border: 10px solid black;
    width: 96px;
  } */
   
  .labelFont {
    font-size: 13px;
  }
  .boldFont {
    font: 16px satoshi-bold;
  }
  .optional {
    font-size: 13px;
    color: #7784A1;
  }
  
  /* .pointerEvent {
    pointer-events: none !important;
  } */
  h4 {
    font-weight: bold;
  }
  p {
    color: #7784a1;
    font-size: 13px;
  }
  .NewEmp {
    color: #1b1922;
  }
  .cont {
    font-size: 14px;
  }
  .edit {
    background-color: #4075d3;
  }
  .fbg {
    background: #f7f8fa 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  .sbg {
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  .nav {
    display: flex;
  }
  .form-control.is-invalid {
    background-image: none;
  }
  .is-invalid {
    color: #dc3545;
    font: 12px Satoshi-Medium;
  }
  .cancelBtn {
    border: 1px solid #d0d0e0;
  }
  .unsaved {
    font: 16px Satoshi-Bold;
    color: #1b1922;
  }
  .changes {
    font-size: 13px;
    color: #1b1922;
  }
  .labelfont {
    font: 13px satoshi-medium !important;
  }
  .labelBold {
    font: 13px satoshi-bold !important;
  }
  .basicFont {
    font: 16px satoshi-bold;
  }
  .profileFont {
    font: 12px satoshi-medium !important;
  }
  .tabScroll {
    white-space: nowrap !important;
  }
  .tabLink {
    color: #7784a1;
    font: 13px satoshi-bold !important;
  }
  .nav {
    flex-wrap: unset;
  }
  .tabLink.active {
    color: #1b1922;
    font: 13px satoshi-bold !important;
  }
  .numval {
    background-color: #4075d3 !important;
  }
  .dNone {
    display: none;
  }
  .imgWidth {
    width: 96px;
    height: 96px;
  }
  @media only screen and (max-width: 1099px) {
    .tabscrollbar {
      white-space: nowrap;
      overflow-x: scroll;
    }
  }
  .bordercol {
    border: 1px solid #d0d0e0;
    border-radius: 5px;
    opacity: 1;
  }
  .feetopic {
    font-size: 13px;
    font-family: Satoshi-Medium;
    letter-spacing: 0px;
    line-height: 17px;
    color: #2f2e40;
  }
  .feetopic1 {
    font-size: 14px;
    font-family: Satoshi-Medium;
    letter-spacing: 0px;
    line-height: 18px;
    color: #2f2e40;
  }
  .feeamount {
    font-size: 13px;
    font-family: GeneralSans-Bold;
    letter-spacing: 0px;
    line-height: 17px;
    color: #2f2e40;
  }
  .totlafeebg1 {
    border-bottom-left-radius: 5px;
    background-color: #f7f8fa;
  }
  .totlafeebg2 {
    border-bottom-right-radius: 5px;
    background-color: #f7f8fa;
  }
  .feetopicbold {
    color: #1b1922;
    font-family: Satoshi-Bold;
    font-size: 16px;
    letter-spacing: 0.32px;
    line-height: 17px;
  }
  .feetopicout {
    font-size: 14px;
    font-family: Satoshi-Medium;
    letter-spacing: 0px;
    line-height: 18px;
    color: #cc5151;
  }
  .feetopicboldout {
    font-size: 16px;
    font-family: Satoshi-Bold;
    letter-spacing: 0px;
    line-height: 22px;
    color: #cc5151;
  }
  .otherfeepaidbg {
    background: #f7f8fa 0% 0% no-repeat padding-box;
    border-radius: 5px;
  }
  .popuptitle {
    font-size: 16px;
    font-family: Satoshi-Bold;
    letter-spacing: 0px;
    line-height: 22px;
    color: #1b1922;
  }
  .imgsizeview {
    height: 32px;
    width: 32px;
  }
  .tabLink {
    letter-spacing: 0px;
    color: #1b1922;
    opacity: 1;
    font-size: 13px;
    font-family: "Satoshi", sans-serif;
    font-weight: 800;
  }
  
  .trheight {
    height: 48px;
  }
  
  .findTable tr:nth-child(even) {
    background: #ffffff;
  }
  
  .findTable tr:nth-child(odd) {
    background: #f7f8fa;
  }
  
  .ngx-pagination a {
    color: #1b1922;
    background-color: #d3dff8;
  }
  
  .trfixedstyle {
    background-color: #ffffff;
  }
  
  tr {
    height: 48px;
  }
  
  thead {
    height: 48px;
  }
  tr:nth-child(even) {
    background: #f7f8fa;
  }
  
  tr:nth-child(odd) {
    background: #ffffff;
  }
  
  .tableData {
    letter-spacing: 0px;
    color: #1b1922;
    opacity: 1;
    font-family: "Satoshi", sans-serif;
    font-weight: 600;
    font-size: 13px;
  }
  
  .tableval {
    border: 1px solid #d0d0e0;
    border-radius: 7px;
    white-space: nowrap;
    overflow-x: auto;
  }
  
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
  
  .tableRow {
    cursor: pointer;
  }
  
  .Idcursor {
    border-bottom: 1px solid #1b1922;
  }
  .tableHead {
    border-bottom: 1px solid #d0d0e0;
    opacity: 1;
  }
  
  .headContent {
    letter-spacing: 0px;
    color: #1b1922;
    opacity: 1;
    font-size: 13px;
    font-family: "Satoshi", sans-serif;
    font-weight: 800;
  }
  td {
    padding-right: 1.5rem;
  }
  .borderEnd {
    border-right: 1px solid #dee2e6;
    min-height: calc(100vh - 94px) !important;
  }
  
  .generateBtn {
    height: 36px;
    background: #4075d3 0% 0% no-repeat padding-box;
    border-radius: 7px;
    color: #ffffff;
    font-size: 12px;
  }
  .inputWidth {
    padding: 9px;
    border: 1px solid #A0AECB !important;
    border-radius: 5px !important;
  }
  .inputWidth1 {
    /* border: 1px solid #a0aecb !important; */
  }
  .heading {
    letter-spacing: 0px;
    color: #1b1922;
    opacity: 1;
    font-family: Satoshi-Bold;
    font-size: 21px;
  }
  .subHeading {
    letter-spacing: 0px;
    color: #7784a1;
    opacity: 1;
  }
  .tabLink {
    letter-spacing: 0px;
    color: #7784a1;
    opacity: 1;
    font-size: 13px;
    font-family: "Satoshi", sans-serif;
    font-weight: 800;
  }
  
  .tabLink.active {
    color: #1b1922;
    border-bottom: 3px solid #4075d3;
  }
  .margin_top {
    margin-top: 28px;
    max-height: calc(100vh - 282px) !important;
    overflow-y: scroll;
  }
  .margin_top::-webkit-scrollbar {
    display: none;
  }
  .labelMargin {
    margin-top: 19px;
  }
  
  .tablecon {
    border: none;
  }
  
  .colwid {
    width: 35%;
    padding-left: 4%;
    letter-spacing: 0px;
    color: #1b1922;
    opacity: 1;
    font-size: 13px;
    font-family: Satoshi-Medium;
    /* font-family: "Satoshi", sans-serif;
    font-weight: 600; */
  }
  
  @media (max-width: 1024px) and (min-width: 768px) {
    .colwid {
      width: 50%;
    }
  }
  
  .trheight {
    height: 48px;
  }
  
  .findTable tr:nth-child(even) {
    background: #ffffff;
  }
  
  .findTable tr:nth-child(odd) {
    background: #f7f8fa;
  }
  
  .coldetais {
    letter-spacing: 0px;
    color: #1b1922;
    opacity: 1;
    font-size: 13px;
    font-family: "Satoshi", sans-serif;
    font-weight: 800;
  }
  .ViewAll {
    letter-spacing: 0px;
    color: #7784a1;
    opacity: 1;
  }
  .imgborder {
    border: 1px solid #a0aecb;
    border-radius: 5px;
    width: 36px;
    height: 36px;
    display: inline-flex;
  }
  .collegeAddress {
    font-size: 11px;
  }
  .highlightword {
    font-size: 16px;
    letter-spacing: 0.32px;
  }
  .receiptAmount {
    border: 1px solid #a0aecb;
    border-radius: 5px;
    opacity: 1;
    height: 42px;
    width: 175px;
    font-size: 16px;
    letter-spacing: 0.32px;
  }
  .commentReceipt {
    font-size: 10px;
    background-color: #f7f8fa;
    color: #7784a1;
    print-color-adjust: exact;
  }
  .saveReceipt {
    border-radius: 5px;
    opacity: 1;
    background-color: #4075d3;
    color: #ffffff;
    height: 36px;
    width: 96px;
  }
  .editReceipt {
    border: 1px solid #d0d0e0;
    border-radius: 5px;
    opacity: 1;
    color: #1b1922;
    height: 36px;
    width: 96px;
  }
  .footerValue {
    background-color: #fedbdb;
  }
  .footerContent {
    color: #cc5151;
  }
  .receiptBorder {
    border: 1px solid #1b192230;
  }
  .receiptBorderRight {
    border-right: 1px solid #1b192230;
  }
  .highlightwordnodata {
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #a0aecb;
  }
  .paddingborder {
    box-shadow: -1px 0px 0px #d0d0e0;
    opacity: 1;
  }
  @media screen and (max-width: 991px) {
    .paddingborder {
      box-shadow: none !important;
      opacity: 1;
    }
    .vh100 {
      min-height: fit-content !important;
    }
  }
  @media screen and (max-width: 768px) {
    .paddingborder {
      box-shadow: none !important;
      opacity: 1;
    }
  }
  .appendprepend {
    height: 36px;
    background-color: white;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 47px;
    height: 21px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .disableswitch {
    opacity: 0.6 !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
  }
  .activeSwitch {
    opacity: 1 !important;
    cursor: pointer !important;
    pointer-events: all;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d0d0e0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: #ffffff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: #d3dff8;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #d3dff8;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .vh100 {
    min-height: calc(100vh - 94px);
  }
  .imgsizealert {
    width: 16px;
    height: 16px;
  }
  @media screen and (max-width: 767px) {
    .receiptBorderRight {
      border-right: none;
    }
    .paddingleftmob {
      padding-left: 0rem !important;
    }
    .alignitemsbtn {
      align-items: left !important;
      justify-content: left !important;
    }
    .collegeAddress {
      text-align: left !important;
    }
    .amountword {
      display: flex;
    }
  }
  .imageicon {
    width: 14px;
    height: 13px;
  }
  .bordercol {
    border: 1px solid #d0d0e0;
    border-radius: 5px;
    opacity: 1;
  }
  .hrBorderr {
    border: 1px solid #d0d0e0;
    opacity: 1;
    margin: 0 0 !important;
  }
  .modal-content {
    box-shadow: 0px 4px 6px #1b192230;
    border-radius: 7px;
    border: 1px solid #1b192230;
  }
  .details {
    font-size: 13px;
    color: #1b1922;
  }
  .detailsName {
    font-size: 16px;
    color: #1b1922;
  }
  .completeProfile {
    color: #4075d3;
    font-size: 12px;
    text-decoration: underline;
  }
  .costname {
    font-size: 14px;
    color: #1b1922;
  }
  .costnameouts {
    font-size: 14px;
    color: #cc5151;
  }
  .costouts {
    font-size: 16px;
    color: #cc5151;
  }
  .cost {
    font-size: 16px;
    color: #1b1922;
  }
  .amtotherFee {
    font-size: 14px;
    color: #1b1922;
    letter-spacing: 0.32px;
  }
  .bacColor {
    background-color: #f7f8fa;
  }
  .cusBtn {
    background-color: #d3f8f1;
  }
  
  .modebtn {
    height: 20px;
    border-radius: 10px;
    opacity: 1;
    color: #1b1922;
    font-size: 12px;
    font-family: "Satoshi", sans-serif;
    font-weight: 700;
  }
  .tdPadding {
    padding-left: 0.5rem;
  }
  @media only screen and (max-width: 991px) {
    .popupscroll {
      overflow-x: scroll;
    }
  }
  .scrollbar1 {
    float: right;
  }
  @media only screen and (max-width: 1000px) {
    .scrollbar {
      white-space: nowrap;
      overflow-x: scroll !important;
    }
    .scrollbar1 {
      float: left !important;
      margin-left: 10px;
    }
  }
  .aadhar[type="number"]::-webkit-outer-spin-button,
  .aadhar[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .aadhar[type="number"] {
    -moz-appearance: textfield;
  }
  .Act {
    color: #1fc1a2;
  }
  .Inact {
    color: #1b1922;
  }
  .Discont {
    color: #cc5151;
  }
  .raditn {
    border: 1px solid #a0aecb !important;
  }
  /* ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,new */
  .fixedposition{
    margin-top: 90px;
    /* margin-left:139px; */
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .fixedposition {
      margin-top: 112px;
      /* margin-left:139px; */
    }
  }
  
  @media (max-width: 767.98px) {
    .fixedposition{
      margin-top: 200px;
      margin-left: 0px; 
    }
  }
  
  .fixedpositions{
    margin-left:139px;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .fixedpositions {
      
      margin-left:139px;
    }
  }
  
  @media (max-width: 767.98px) {
    .fixedpositions{
    
      margin-left: 0px; 
    }
  } 
  .alertm{
    font: 13px satoshi-bold !important;
    color: #d3170a;
  }
  .scroll{
    /* overflow-x: auto;  */
    white-space: nowrap; 
    width: 100%;
  }
  .bgadd{
    background-color: #F7F8FA;
  }
  .link {
    color: inherit;
    text-decoration: none; 
  }
  @media (min-width: 836px) {
    .uborder.active {

    }
  }
  .basic-info-td {
    width:-15px; 
  }
  
  .academics-info-td {
    /* width: 140px;  */
  }
  @media(min-width: 321px) and (max-width: 390px) {
    
    .CreateBtn {
      /* margin-top: -65px;  */
    }
    .prevBtn {
      margin-top: 10px; 
    }
  }
  @media(max-width: 319px) {
    
    .CreateBtn {
      margin-top: 10px; 
      /* margin-right: 40px; */
    }
    .prevBtn {
      margin-top: 0px; 
    }
  }
  .non-selectable {
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    pointer-events: none; 

}
.blueround {
  /* display: inline-block; */
  border-radius: 50%;
  background-color: #4075D3;
  font-size: 11px;
  color: white;
  width: 20px;
  height: 16px;
  padding-left: 2px;
  padding-right: 2px;
  cursor: pointer;
  position: relative;
}

.tooltip-text {
  visibility: visible;
  width: 150px;
  background-color: rgb(243, 243, 255);
  color: #7784A1;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%; 
  left: 50%;
  margin-left: -75px; 
  opacity: 1;
  transition: opacity 0.3s;
}
.addMore{
  background-color: #F7F8FA !important;
  color: #4075D3!important;
  font: 15px satoshi-medium !important;
}
.selectFile{
  font-size: 10px;
}
.input-container {
  /* position: relative; */
  display: flex;
  align-items: center;
  position: relative;
}
.vertical-line {
  position: absolute;
  height: 100%;
  left: calc(80% - 1px); 
  border-left: 1px solid #ccc; 
}
/* .horizontal-line {
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ccc;
} */
 
.cgpa-line {
  position: absolute;
  height: 100%;
  left: calc(60% - 1px); 
  border-left: 1px solid #ccc; 
}

.percentage-sign {
  position: absolute;
  right: 10px; 
  top: 50%;
  transform: translateY(-50%);
  color: #7784a1;
  font-size: 14px;
}

.labelinput .form-control.disabled-input-label {
  background-color: #e9ecef;
}

.flexother{
  display: flex;
}

@media screen and (min-width: 300px) and (max-width:575px) {
  .flexother {
    display: flex;
    flex-direction: column;
  }
}
.bordernone{
  scrollbar-width: none;
}
::-webkit-calendar-picker-indicator{ 
  background-image: url('../../../../assets/calendar.png');
      background-position:center;
       background-repeat:no-repeat;
}
input[type="date"]{
  height: 38px;
}
input[type="month"]{
  height: 38px;
}
input[type=date][value=""]:not(:focus) { 
  color: transparent; 
  content: attr(placeholder) !important;
}
input[type=month][value=""]:not(:focus) { 
  color: transparent; 
  content: attr(placeholder) !important;
}
.dateplaceholder {
  color:grey;
  font-size:12px;
  font-family: "Satoshi medium";
}
input[type="date"][value=""]:not(:focus) ~ .dateplaceholder {
  display: block;
}
input[type="date"]:focus ~ .dateplaceholder {
  display: none;
}
input[type="date"]:valid ~ .dateplaceholder {
  display: none;
}
input[type="date"]:invalid ~ .dateplaceholder {
  display: none;
}
input[type=month][value=""]:not(:focus) ~ .dateplaceholder { 
  display: block;
}
input[type="month"]:focus ~ .dateplaceholder {
  display: none;
}
input[type="month"]:valid ~ .dateplaceholder {
  display: none;
}
input[type="month"]:invalid ~ .dateplaceholder {
  display: none;
}
