.containersAY{
    padding: 16px 0 0 18px !important;
    width: 258px !important; 
    height:153px !important; 
}

.editlogo{
 padding-right: 12px;
 height: 13px;
 /* width: 13px; */
}

.year{
    font: 13px Satoshi-bold;
}

.oddRow{
    padding-top: 18px ;
}

.evenRow{
    padding-top: 16px;
}

.oddRow span:nth-child(1){
    font: 12px satoshi medium;
    /* color: #7784A1; */
}

.oddRow span:nth-child(2){
    font: 12px satoshi medium;
    color: #7784A1;
}

.evenRow span:nth-child(1){
    font: 12px satoshi medium;
}

.evenRow span:nth-child(2){
    font: 12px satoshi medium;
    color: #7784A1;
}

.box {
    text-align: center; /* Centers the content within each box */
    margin: 10px; /* Adds margin between boxes */
    padding: 10px; /* Adds padding inside each box */
    border: 1px solid #ddd; /* Adds a simple border (optional) */
  }
  