.input-container {
    position: relative;
  }
  .percentage-symbol {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 16px;
    color: #000;
  }
  
  .currency-symbol {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 16px;
    color: #000; 
  }
  .currency .form-control {
    padding-left: 35px;
  }
  .country-symbol {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 16px;
    color: #000; 
  }
  .country .form-control {
    padding-left: 40px;
  }