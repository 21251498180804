.nav-tabs {
    border-bottom: none;
  }

  .nav-link {
    border: none;
    font: 13px satoshi-bold !important;
    color: #7784a1;
  }

  .nav-link.active {
    color: #1b1922;
    border-bottom: 0.125rem solid #4075d3 !important;
    border-radius: 0.125rem 0.125rem 0.125rem 0.125rem !important;
    border-width: 0.188rem !important;
    padding-left: 0rem;
    padding-right: 0rem;
    font: 13px satoshi-bold !important;
  }

  .greenColor {
    background-color: #D3F8F1;
    border-radius: 5px;
  }

  .num {
    font-size: 10px;
    background: #d0d0e0;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-link.active.num {
    background: #4075d3 0% 0% no-repeat padding-box !important;
    color: #fff !important;
  }

  .CreateBtn,
  .CreateBtn:active {
    background-color: #4075d3;
    color: #ffffff;
    border-radius: 5px;
    opacity: 1;
    height: 36px;
    width: 96px;
    font-size: 12px;
  }
  
  .prevBtn,
  .prevBtn:active {
    border: 1px solid #d0d0e0;
    width: 96px;
  }

  .labelFont {
    font-size: 13px;
  }

  .boldFont {
    font: 16px satoshi-bold;
  }

  .optional {
    font-size: 12px;
    color: #7784a1;
  }

  .pointerEvent {
    pointer-events: none !important;
  }

  h4 {
    font-weight: bold;
  }

  p {
    color: #7784a1;
    font-size: 13px;
  }

  .NewEmp {
    color: #1b1922;
  }
  
  .cont {
    font-size: 14px;
  }
  .edit {
    background-color: #4075d3;
  }
  .fbg {
    background: #f7f8fa 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  .sbg {
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  .nav {
    display: flex;
  }
  .form-control.is-invalid {
    background-image: none;
  }
  .is-invalid {
    color: #dc3545;
    font: 12px Satoshi-Medium;
  }
  .cancelBtn {
    border: 1px solid #d0d0e0;
  }
  .unsaved {
    font: 16px Satoshi-Bold;
    color: #1b1922;
  }
  .changes {
    font-size: 13px;
    color: #1b1922;
  }
  .labelfont {
    font: 13px satoshi-medium !important;
  }
  .labelBold {
    font: 13px satoshi-bold !important;
  }
  .basicFont {
    font: 16px satoshi-bold;
  }
  .profileFont {
    font: 12px satoshi-medium !important;
  }
  .tabScroll {
    white-space: nowrap !important;
  }
  .tabLink {
    color: #7784a1;
    font: 13px satoshi-bold !important;
  }
  .nav {
    flex-wrap: unset;
  }
  .tabLink.active {
    color: #1b1922;
    font: 13px satoshi-bold !important;
  }
  .numval {
    background-color: #4075d3 !important;
  }
  .dNone {
    display: none;
  }
  .imgWidth {
    width: 96px;
    height: 96px;
  }
  @media only screen and (max-width: 1099px) {
    .tabscrollbar {
      white-space: nowrap;
      overflow-x: scroll;
    }
  }
  .bordercol {
    border: 1px solid #d0d0e0;
    border-radius: 5px;
    opacity: 1;
  }
  .feetopic {
    font-size: 13px;
    font-family: Satoshi-Medium;
    letter-spacing: 0px;
    line-height: 17px;
    color: #2f2e40;
  }
  .feetopic1 {
    font-size: 14px;
    font-family: Satoshi-Medium;
    letter-spacing: 0px;
    line-height: 18px;
    color: #2f2e40;
  }
  .feeamount {
    font-size: 13px;
    font-family: GeneralSans-Bold;
    letter-spacing: 0px;
    line-height: 17px;
    color: #2f2e40;
  }
  .bg{
   border-bottom: 3px solid #4075d3;
  }
  .fixed{
    position: fixed;
  }
  .under{
    border-bottom: 0.125rem solid #D0D0E0 !important;
    color: #7784a1;
    width: 100vw;
    margin-bottom: 1.7rem;
  }
  @media only screen and (max-width:767px){
    .under{
      width: 100% !important;
    } 
  }
  .emptype {
    display: inline-block;
    border-radius: 10px;
    background-color: #E1EFFF;
    color: black; 
    /* min-width: 59px; */
    /* min-height: 20px; */
    /* width: 60px;*/
    height: 20px;
    cursor: pointer;
    position: relative;
    margin-top: 10px;
  }

  .clickBtn{
    color: #4075D3;
    text-decoration: underline;
    cursor: pointer;
  }

  .empData{
    font-size: 20px;
    margin-top: 30px;
    font-weight:bold;
  }

  .imgW{
    top: 188px;
    left: 270px;
    width: 96px;
    height: 96px;
  }
  @media only screen and (min-width:992px){
    #employeebioid{
white-space: nowrap;
    }
  }
  