.deactivebtn {
  background-color: #CC5151 !important; 
  color: #ffffff !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  height: 36px !important;
  width: 96px !important;
  font-size: 12px !important;
  border: none !important;
}

  .viewbox {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: 1px solid #D0D0E0;
  }
  .pendingPadding {
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
.activebox{
  background-color: #C4F0DC;
  color: #1F6946;
  border-radius: 5px;
  height: 40px;
  width: 138px;
  font-weight: bold;
}
.hrl{
  border: 1px solid #D0D0E0;
  
}

.error{
  color: red;
  padding-top: 20px;
}
div.custom-col-width {
  max-width: 380px;
}
#employeeView{
  white-space: nowrap;
}
@media only screen and (max-width:370px){
  #employeeView{
    font-size: 10px;
    white-space: nowrap;
  }
}
