.tabLink {
    letter-spacing: 0px;
    color: #7784a1;
    opacity: 1;
    font-size: 13px;
    font-family: "Satoshi", sans-serif;
    font-weight: 800;
  }
  .tabLink.active {
    color: #1b1922;
    border-bottom: 3px solid #4075d3;
  }
  .bg{
    border-bottom: 3px solid #4075d3;
  }
  .batchBorder {
    border: 1px solid #d0d0e0;
    border-radius: 5px;
    opacity: 1;
    height: 48px;
  }
  .batchYear {
    font-size: 13px;
    font-family: Satoshi-Bold;
    letter-spacing: 0px;
    color: #1b1922;
    opacity: 1;
  }
  .CreateBtn,
.CreateBtn:active {
  background-color: #4075d3;
  color: #ffffff;
  border-radius: 5px;
  opacity: 1;
  height: 36px;
  width: 96px;
  font-size: 12px;
}
.labelFont {
  font-size: 13px;
}
.addnewbtn{
  border:none;
  border-radius: 3px;
  font-size: 12px;
  padding: 4px;
  background-color:#4075D3;
  height:36px;
  width:92px;
}
.me-4-5 {
  margin-right: 1.85rem; 
}
